var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.modals.nightOrder && _vm.roles.size)?_c('Modal',{staticClass:"night-reference",class:{
    storyteller: !_vm.session.isSpectator,
  },on:{"close":function($event){return _vm.toggleModal('nightOrder')}}},[_c('font-awesome-icon',{staticClass:"toggle",attrs:{"icon":"address-card","title":"Show Character Reference"},on:{"click":function($event){return _vm.toggleModal('reference')}}}),_c('h3',[_vm._v(" Night Order "),_c('br'),_c('font-awesome-icon',{attrs:{"icon":"cloud-moon"}}),_vm._v(" "+_vm._s(_vm.edition.name || "Custom Script")+" ")],1),_c('div',{staticClass:"night"},[_c('ul',{staticClass:"first"},[_c('li',{staticClass:"headline"},[_vm._v("First Night")]),_vm._l((_vm.rolesFirstNight),function(role){return _c('li',{key:role.name,class:[role.team]},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(role.name)+" "),(role.players.length)?_c('span',{staticClass:"player"},[_c('br'),_vm._l((role.players),function(player,index){return _c('small',{key:index,class:{ dead: player.isDead }},[(!_vm.session.isSpectator)?[_c('div',{staticClass:"option",on:{"click":function($event){return _vm.setResponded(player, role.id)}}},[_vm._v(" "+_vm._s(player.name)+" "),_c('font-awesome-icon',{attrs:{"icon":[
                      'fas',
                      !!player.hasResponded[role.id]
                        ? 'check-square'
                        : 'square',
                    ]}})],1)]:_vm._e(),(_vm.session.isSpectator)?[_vm._v(_vm._s(player.name + (role.players.length > index + 1 ? "," : "")))]:_vm._e()],2)})],2):_vm._e()]),(role.id)?_c('span',{staticClass:"icon",style:({
            backgroundImage: `url(${
              role.image && _vm.grimoire.isImageOptIn
                ? role.image
                : require(
                    '../../assets/icons/' +
                      (role.imageAlt || role.id) +
                      '.png',
                  )
            })`,
          })}):_vm._e(),(role.firstNightReminder)?_c('span',{staticClass:"reminder"},[_vm._v(" "+_vm._s(role.firstNightReminder)+" ")]):_vm._e()])})],2),_c('ul',{staticClass:"other"},[_c('li',{staticClass:"headline"},[_vm._v("Other Nights")]),_vm._l((_vm.rolesOtherNight),function(role){return _c('li',{key:role.name,class:[role.team]},[(role.id)?_c('span',{staticClass:"icon",style:({
            backgroundImage: `url(${
              role.image && _vm.grimoire.isImageOptIn
                ? role.image
                : require(
                    '../../assets/icons/' +
                      (role.imageAlt || role.id) +
                      '.png',
                  )
            })`,
          })}):_vm._e(),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(role.name)+" "),(role.players.length)?_c('span',{staticClass:"player"},[_c('br'),_vm._l((role.players),function(player,index){return _c('small',{key:index,class:{ dead: player.isDead }},[(!_vm.session.isSpectator)?[_c('div',{staticClass:"option",on:{"click":function($event){return _vm.setResponded(player, role.id)}}},[_c('font-awesome-icon',{attrs:{"icon":[
                      'fas',
                      !!player.hasResponded[role.id]
                        ? 'check-square'
                        : 'square',
                    ]}}),_vm._v(" "+_vm._s(player.name)+" ")],1)]:_vm._e(),(_vm.session.isSpectator)?[_vm._v(_vm._s(player.name + (role.players.length > index + 1 ? "," : "")))]:_vm._e()],2)})],2):_vm._e()]),(role.otherNightReminder)?_c('span',{staticClass:"reminder"},[_vm._v(" "+_vm._s(role.otherNightReminder)+" ")]):_vm._e()])})],2)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }